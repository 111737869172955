<template>
  <div>
    <h1
      class="page-title"
    >
      Logout
    </h1>
    <div v-if="!isAuthenticated && !profile">
      You have been logged out.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
      isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  created: function () {
    this.logout()
  },
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout')
    }
  },
}
</script>
